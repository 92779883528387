// import logo from './logo.svg';
import './App.css';
import Web3 from 'web3';
import { useEffect, useState } from 'react';
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import arcticpool from "./assets/arcticpool.png";
import southernpool from "./assets/southernpool.png";
import indianpool from "./assets/indianpool.png";
import atlanticpool from "./assets/atlanticpool.png";
import pacificpool from "./assets/pacificpool.png";
import certik from "./assets/certik.png";


import { PlusOutlined,MinusOutlined } from "@ant-design/icons";

import { useMoralis, useMoralisWeb3Api } from "react-moralis";
function App() {
  // var Web3 = require('web3'); 
  const Web3Api = useMoralisWeb3Api();
const node_URL = 'https://nd-921-411-021.p2pify.com/86520991c51fd40a6bd3c263c04271fc'; 
const { authenticate, isAuthenticated, isAuthenticating, user, account: walletAddress, logout, Moralis } = useMoralis();

const [arcticApr, setArcticApr] = useState("");
const [arcticTotalStaked,setArcticTotalStaked] = useState("")
const [southernTotalStaked,setSouthernTotalStaked] = useState("")
const [indianTotalStaked,setIndianTotalStaked] = useState("")
const [atlanticTotalStaked,setAtlanticTotalStaked] = useState("")
const [pacificTotalStaked,setPacificTotalStaked] = useState("")
  const [southernApr, setSouthernApr] = useState("");

  const [indianApr, setIndianApr] = useState("");
  const [atlanticApr, setAtlanticApr] = useState("");
  const [pacificApr, setPacificApr] = useState("");
  const [viewMore, setViewMore] = useState(false);
  const [viewMore1, setViewMore1] = useState(false);
  const [viewMore2, setViewMore2] = useState(false);
  const [viewMore3, setViewMore3] = useState(false);
  const [viewMore4, setViewMore4] = useState(false);
  const [userStackedArc, setUserStackedArc] = useState("");
  const [userStackedSou, setUserStackedSou] = useState("");
  const [userStackedInd, setUserStackedInd] = useState("");
  const [userStackedAtl, setUserStackedAtl] = useState("");
  const [userStackedPac, setUserStackedPac] = useState("");
  const [userLockedArc, setUserLockedArc] = useState("");
  const [userLockedSou, setUserLockedSou] = useState("");
  const [userLockedInd, setUserLockedInd] = useState("");
  const [userLockedAlt, setUserLockedAlt] = useState("");
  const [userLockedPac, setUserLockedPac] = useState("");
  const [southernPend, setSouthernPend] = useState("");
  const [indianPend, setIndianPend] = useState("");
  const [atlanticPend, setAtlanticPend] = useState("");
  const [arcticPend, setArcticPend] = useState("");
  const [pacificPend, setPacificPend] = useState("");
  const [tokenPrice, setTokenPrice] = useState("");
  const [remainCon, setRemainCon] = useState([]);
const [remainCon2, setRemainCon2] = useState([]);
const [loadingpage, setLoadinpage] = useState(false);
const [value, setValue] = useState("");
const [stakePool, setStakePool] = useState("");
const [open, setOpen] = useState(false);
const [userToken, setUserToken] = useState(0);
const [coinPrice, setCoinPrice] = useState("");
const [userWalletMake, setUserWalletMake] = useState("");
const startBlocksAbi =  [
  {
    inputs: [],
    name: "startBlock",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];
const lockupsAbi =  [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "lockups",
    outputs: [
      {
        internalType: "uint8",
        name: "stakeType",
        type: "uint8",
      },
      {
        internalType: "uint256",
        name: "duration",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "depositFee",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "withdrawFee",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "rate",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "accTokenPerShare",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "lastRewardBlock",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "totalStaked",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "totalStakedLimit",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];
const pendingAbi=[
  {
    inputs: [
      {
        internalType: "address",
        name: "_account",
        type: "address",
      },
      {
        internalType: "uint8",
        name: "_stakeType",
        type: "uint8",
      },
    ],
    name: "pendingReward",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

useEffect(()=>{
  // web3.eth.getAccounts().then(console.log);
  // starting()
  // startPage()
  // if (typeof window.ethereum !== 'undefined') {
  //   console.log('MetaMask is installed!');
  //   window.ethereum.request({ method: 'eth_requestAccounts' });
  // }
  
 
  console.log(node_URL)
  // if (typeof(Storage) !== "undefined") {
  //  console.log("storage available")
  // } else {
  //   // Sorry! No Web Storage support..
  //   console.log("storage not available")
  // }

  detectCurrentProvider()
  if (isAuthenticated) {
    // add your logic here
    console.log('1')
    console.log(isAuthenticated)

      const usertAddresss=user.get("ethAddress")
      setUserWalletMake(user.get("ethAddress"))
      // console.log(usertAddresss)
      getUserStakes()
      fetchTokenBalances()

    
  }
  else{
    setLoadinpage(true)
  }

},[isAuthenticated])
const login = async () => {
  if (typeof window.ethereum !== 'undefined') {
    if (!isAuthenticated) {

      await authenticate({signingMessage: "H2ON Securities Authentication" })
        .then(function (user) {
          console.log("logged in user:", user);
          console.log(user.get("ethAddress"));
          setUserWalletMake(user.get("ethAddress"))
          getUserStakes()
          fetchTokenBalances()
          
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  else {
    alert("No Web3 found on your browser");
  }
}




const detectCurrentProvider =async()=>{
  // let provider;
  // let provider =window.etherum;
 

 
    // console.log("no provider found")
    console.log(window.ethereum)
    var web3 = new Web3(node_URL) 
    web3.eth.getBlockNumber((err,block) => { 
        console.log("latest block", block) 
    })
   var startBlocks = new web3.eth.Contract(
      startBlocksAbi,
      "0x0660C63c1D1fc1Ee5194bBB59b4844da9C7a849F"
    )
    
    startBlocks.methods.startBlock().call(function (err, res) {
      if (err) {
        console.log("An error occured", err)
        return
      }
      console.log("The balance is: ", res)
    })
    var lockupsInfo = new web3.eth.Contract(
      lockupsAbi,
      "0x7348E415595e9396acE8Ce485c4DB85D71990076"
    )
    lockupsInfo.methods.lockups("0").call(function (err, res) {
      if (err) {
        console.log("An error occured", err)
        return
      }
      // console.log("Arctic is: ", res.rate)
      const apr =
      ((res.rate * 28800 * 365) / res.totalStaked) * 100;
    setArcticApr(apr.toFixed(2));
    // console.log("apr:", apr.toFixed(2) )
    const artictStakedTotal = (res.totalStaked / 1000000000000000000).toFixed(3)
    setArcticTotalStaked(artictStakedTotal)
      
    })
    lockupsInfo.methods.lockups("1").call(function (err, res) {
      if (err) {
        console.log("An error occured", err)
        return
      }
      // console.log("Southern is: ", res)
      const apr =
      ((res.rate * 28800 * 365) / res.totalStaked) * 100;
    setSouthernApr(apr.toFixed(2));
    // console.log("apr:", apr.toFixed(2) )
    const artictStakedTotal = (res.totalStaked / 1000000000000000000).toFixed(3)
    setSouthernTotalStaked(artictStakedTotal)
    })
    lockupsInfo.methods.lockups("2").call(function (err, res) {
      if (err) {
        console.log("An error occured", err)
        return
      }
      // console.log("Indian is: ", res)
      const apr =
      ((res.rate * 28800 * 365) / res.totalStaked) * 100;
    setIndianApr(apr.toFixed(2));
    // console.log("apr:", apr.toFixed(2) )
    const artictStakedTotal = (res.totalStaked / 1000000000000000000).toFixed(3)
    setIndianTotalStaked(artictStakedTotal)
    })
    lockupsInfo.methods.lockups("3").call(function (err, res) {
      if (err) {
        console.log("An error occured", err)
        return
      }
      // console.log("Atlantic is: ", res.totalStaked)
      const apr =
      ((res.rate * 28800 * 365) / res.totalStaked) * 100;
    setAtlanticApr(apr.toFixed(2));
    // console.log("apr:", apr.toFixed(2) )
    const artictStakedTotal = (res.totalStaked / 1000000000000000000).toFixed(3)
    setAtlanticTotalStaked(artictStakedTotal)
    })
    var lockupsInfoPacific = new web3.eth.Contract(
      lockupsAbi,
      "0x0660C63c1D1fc1Ee5194bBB59b4844da9C7a849F"
    )
    lockupsInfoPacific.methods.lockups("0").call(function (err, res) {
      if (err) {
        console.log("An error occured", err)
        return
      }
      // console.log("Pacific is: ", res)
      const apr =
      ((res.rate * 28800 * 365) / res.totalStaked) * 100;
    setPacificApr(apr.toFixed(2));
    // console.log("apr:", apr.toFixed(2) )
    const artictStakedTotal = (res.totalStaked / 1000000000000000000).toFixed(3)
    setPacificTotalStaked(artictStakedTotal)
    })
    fetch(
      `https://api.bscscan.com/api?module=block&action=getblockcountdown&blockno=29855440&apikey=BCZCU2KE2YM9XSYEHDPJXYYRQC5MMRJ6MX`,
      {
        method: "GET",
      },
    )
      .then((putresponse) => putresponse.json())
      .then((putresponse) => {
        console.log(putresponse);
        setRemainCon(putresponse.result.RemainingBlock);
        fetch(
          `https://api.bscscan.com/api?module=block&action=getblockcountdown&blockno=40367146&apikey=BCZCU2KE2YM9XSYEHDPJXYYRQC5MMRJ6MX`,
          {
            method: "GET",
          },
        )
          .then((putresponsen) => putresponsen.json())
          .then((putresponsen) => {
            console.log(putresponsen);
            setRemainCon2(putresponsen.result.RemainingBlock);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });

    if(typeof window.ethereum !== 'undefined'){
      // provider=window.etherum
      console.log(isAuthenticated)
   
    }
  // return provider
}
const getUserStakes =async()=>{
  // setLoadinpage(false)
  const usertAddresss=user.get("ethAddress")
  await Moralis.enableWeb3();

  // console.log(usertAddresss)
  // const web3Js = new Web3(Moralis.provider)
  // await console.log(usertAddresss)
  // const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
 // Get ethers.js web3Provider

  // const signer = web3Provider.getSigner();
  // const usertAddresss = await walletAddress
  console.log("getting results");
  // setUserToken(0);
  const optionsp = {
    address: "0xE0E81c29A68bFDd7c48072fD94e7c58F1f0146C1",
    chain: "bsc",
  };
  const price = await Web3Api.token.getTokenPrice(optionsp);
  setCoinPrice(price.usdPrice.toFixed(2));
  setTokenPrice(price.usdPrice.toFixed(2));
  console.log(price)
  console.log("user Products")
  const options6 = {
    contractAddress: "0x0660C63c1D1fc1Ee5194bBB59b4844da9C7a849F",

    functionName: "pendingReward",
  
    abi: [
      {
        inputs: [
          {
            internalType: "address",
            name: "_account",
            type: "address",
          },
          {
            internalType: "uint8",
            name: "_stakeType",
            type: "uint8",
          },
        ],
        name: "pendingReward",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],

    params: {
      _account: usertAddresss,
      _stakeType: "0",
    },
  };
  const rewardsTo = await Moralis.executeFunction(options6);

  console.log("done")
  const testconv = Moralis.Units.FromWei(rewardsTo);
  const options7 = {
    contractAddress: "0x7348E415595e9396acE8Ce485c4DB85D71990076",

    functionName: "pendingReward",

    abi: [
      {
        inputs: [
          {
            internalType: "address",
            name: "_account",
            type: "address",
          },
          {
            internalType: "uint8",
            name: "_stakeType",
            type: "uint8",
          },
        ],
        name: "pendingReward",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],

    params: {
      _account: usertAddresss,
      _stakeType: "1",
    },
  };
  const rewardsTo1 = await Moralis.executeFunction(options7);

  const options8 = {
    contractAddress: "0x7348E415595e9396acE8Ce485c4DB85D71990076",

    functionName: "pendingReward",

    abi: [
      {
        inputs: [
          {
            internalType: "address",
            name: "_account",
            type: "address",
          },
          {
            internalType: "uint8",
            name: "_stakeType",
            type: "uint8",
          },
        ],
        name: "pendingReward",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],

    params: {
      _account: usertAddresss,
      _stakeType: "2",
    },
  };
  const rewardsTo2 = await Moralis.executeFunction(options8);

  const options9 = {
    contractAddress: "0x7348E415595e9396acE8Ce485c4DB85D71990076",

    functionName: "pendingReward",

    abi: [
      {
        inputs: [
          {
            internalType: "address",
            name: "_account",
            type: "address",
          },
          {
            internalType: "uint8",
            name: "_stakeType",
            type: "uint8",
          },
        ],
        name: "pendingReward",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],

    params: {
      _account: usertAddresss,
      _stakeType: "3",
    },
  };
  const rewardsTo3 = await Moralis.executeFunction(options9);

  const options10 = {
    contractAddress: "0x7348E415595e9396acE8Ce485c4DB85D71990076",

    functionName: "pendingReward",

    abi: [
      {
        inputs: [
          {
            internalType: "address",
            name: "_account",
            type: "address",
          },
          {
            internalType: "uint8",
            name: "_stakeType",
            type: "uint8",
          },
        ],
        name: "pendingReward",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],

    params: {
      _account: usertAddresss,
      _stakeType: "0",
    },
  };
  const rewardsTo4 = await Moralis.executeFunction(options10);
  setArcticPend((rewardsTo4._hex / 1000000000000000000).toFixed(5));

  const options11 = {
    contractAddress: "0x7348E415595e9396acE8Ce485c4DB85D71990076",

    functionName: "userInfo",

    abi: [
      {
        inputs: [
          {
            internalType: "uint8",
            name: "_stakeType",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "_account",
            type: "address",
          },
        ],
        name: "userInfo",
        outputs: [
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "available",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "locked",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],

    params: {
      _account: usertAddresss,
      _stakeType: "0",
    },
  };
  const userStakedarc = await Moralis.executeFunction(options11);
  const userStakedConarc = (
    Moralis.Units.FromWei(userStakedarc.amount._hex, 3) / 1000000000000000
  ).toFixed(3);
  const userLockedConarc = (
    Moralis.Units.FromWei(userStakedarc.amount._hex, 3) / 1000000000000000
  ).toFixed(3);
  setUserStackedArc(userStakedConarc);
  setUserLockedArc(userLockedConarc);
  const options12 = {
    contractAddress: "0x7348E415595e9396acE8Ce485c4DB85D71990076",

    functionName: "userInfo",

    abi: [
      {
        inputs: [
          {
            internalType: "uint8",
            name: "_stakeType",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "_account",
            type: "address",
          },
        ],
        name: "userInfo",
        outputs: [
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "available",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "locked",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],

    params: {
      _account: usertAddresss,
      _stakeType: "1",
    },
  };
  const userStakedsou = await Moralis.executeFunction(options12);
  const userStakedConsou = (
    Moralis.Units.FromWei(userStakedsou.amount._hex, 3) / 1000000000000000
  ).toFixed(3);
  const userLockedConsou = (
    Moralis.Units.FromWei(userStakedsou.amount._hex, 3) / 1000000000000000
  ).toFixed(3);
  setUserLockedSou(userLockedConsou);
  setUserStackedSou(userStakedConsou);
  const options13 = {
    contractAddress: "0x7348E415595e9396acE8Ce485c4DB85D71990076",

    functionName: "userInfo",

    abi: [
      {
        inputs: [
          {
            internalType: "uint8",
            name: "_stakeType",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "_account",
            type: "address",
          },
        ],
        name: "userInfo",
        outputs: [
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "available",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "locked",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],

    params: {
      _account: usertAddresss,
      _stakeType: "2",
    },
  };
  const userStakedind = await Moralis.executeFunction(options13);
  const userStakedConind = (
    Moralis.Units.FromWei(userStakedind.amount._hex, 3) / 1000000000000000
  ).toFixed(3);

  const userLockedConind = (
    Moralis.Units.FromWei(userStakedind.amount._hex, 3) / 1000000000000000
  ).toFixed(3);
  setUserStackedInd(userStakedConind);
  setUserLockedInd(userLockedConind);
  const options14 = {
    contractAddress: "0x7348E415595e9396acE8Ce485c4DB85D71990076",

    functionName: "userInfo",

    abi: [
      {
        inputs: [
          {
            internalType: "uint8",
            name: "_stakeType",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "_account",
            type: "address",
          },
        ],
        name: "userInfo",
        outputs: [
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "available",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "locked",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],

    params: {
      _account: usertAddresss,
      _stakeType: "3",
    },
  };
  const userStakedatl = await Moralis.executeFunction(options14);
  const userStakedConatl = (
    Moralis.Units.FromWei(userStakedatl.amount._hex, 3) / 1000000000000000
  ).toFixed(3);
 
  const userLockedConatl = (
    Moralis.Units.FromWei(userStakedatl.amount._hex, 3) / 1000000000000000
  ).toFixed(3);
  setUserLockedAlt(userLockedConatl);
  setUserStackedAtl(userStakedConatl);

  const options15 = {
    contractAddress: "0x0660C63c1D1fc1Ee5194bBB59b4844da9C7a849F",

    functionName: "userInfo",

    abi: [
      {
        inputs: [
          {
            internalType: "uint8",
            name: "_stakeType",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "_account",
            type: "address",
          },
        ],
        name: "userInfo",
        outputs: [
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "available",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "locked",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],

    params: {
      _account: usertAddresss,
      _stakeType: "0",
    },
  };
  const userStakedpac = await Moralis.executeFunction(options15);
  const userStakedConpac = (
    Moralis.Units.FromWei(userStakedpac.amount._hex, 3) / 1000000000000000
  ).toFixed(3);
  const userLockedConpac = (
    Moralis.Units.FromWei(userStakedpac.amount._hex, 3) / 1000000000000000
  ).toFixed(3);
  setUserLockedPac(userLockedConpac);
  setUserStackedPac(userStakedConpac);
  const chaneToString = (rewardsTo._hex / 1000000000000000000)
  .toFixed(5)
  .toString();
const chaneToString1 = (rewardsTo1._hex / 1000000000000000000)
  .toFixed(5)
  .toString();
const chaneToString2 = (rewardsTo2._hex / 1000000000000000000)
  .toFixed(5)
  .toString();
const chaneToString3 = (rewardsTo3._hex / 1000000000000000000)
  .toFixed(5)
  .toString();
const chaneToString4 = (rewardsTo4._hex / 1000000000000000000)
  .toFixed(5)
  .toString();
setArcticPend(chaneToString4);
setIndianPend(chaneToString2);
setSouthernPend(chaneToString1);
setAtlanticPend(chaneToString3);
setPacificPend(chaneToString);
  console.log(userStakedConpac)
  
  

}
const handleOpen = async (item) => {
 
  console.log(item);
  if (typeof window.ethereum !== 'undefined') {
    if (!isAuthenticated) {
      console.log("user not Logged in")
      await Moralis.authenticate({ signingMessage: "H2ON Securities Authentication" })
      .then(function (user) {
        console.log(user.get("ethAddress"));
        setUserWalletMake(user.get("ethAddress"))
        getUserStakes()
        fetchTokenBalances()
      })
      .catch(function (error) {
        console.log(error);
      });
      

    } else {
      setStakePool(item);
      setOpen(true);
      // const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      console.log("user Logged in")
      // console.log(accounts.value)
    }
  }
  else {
    alert("No Web3 found on your browser");
  }
};

const handleCompound = async (item) => {
  await console.log(item);
  await Moralis.enableWeb3();
  if ( !isAuthenticated) {
    alert("Please Connect to a wallet");
  } else {
    setStakePool(item);
    if (item === 4) {
      console.log("compound 4");
      const options = {
        contractAddress: "0x0660C63c1D1fc1Ee5194bBB59b4844da9C7a849F",
        functionName: "compoundReward",
        abi: [
          {
            inputs: [
              {
                internalType: "uint8",
                name: "_stakeType",
                type: "uint8",
              },
            ],
            name: "compoundReward",
            outputs: [],
            stateMutability: "payable",
            type: "function",
          },
        ],
        params: {
          _stakeType: "0",
        },
        msgValue: Moralis.Units.ETH(0.0035),
      };
      const rewardsTo = await Moralis.executeFunction(options);
      console.log(rewardsTo);
    } else {
      console.log("failed");
      const options = {
        contractAddress: "0x7348E415595e9396acE8Ce485c4DB85D71990076",
        functionName: "compoundReward",
        abi: [
          {
            inputs: [
              {
                internalType: "uint8",
                name: "_stakeType",
                type: "uint8",
              },
            ],
            name: "compoundReward",
            outputs: [],
            stateMutability: "payable",
            type: "function",
          },
        ],
        params: {
          _stakeType: item,
        },
        msgValue: Moralis.Units.ETH(0.0035),
      };
      const rewardsTo = await Moralis.executeFunction(options);
      console.log(rewardsTo);
    }
    // setOpenStaked(item);
    // console.log(openStaked);
  }
};

const handleClose = () => setOpen(false);

const handleSliderChange = (event, newValue) => {
  setValue(newValue);
};
const handleSliderChangeCustom = (item) => {
  if (item === 1) {
    const customTextG = userToken / 4;
    console.log(customTextG);
    setValue(customTextG);
  } else if (item === 2) {
    const customTextG = userToken / 2;
    console.log(customTextG);
    setValue(customTextG);
  } else if (item === 3) {
    const customTextG = (userToken / 4) * 3;
    console.log(customTextG);
    setValue(customTextG);
  } else if (item === 4) {
    const customTextG = (userToken / 4) * 4;
    console.log(customTextG);
    setValue(customTextG);
  } else {
    return null;
    // setValue(newValue);
  }
};
const fetchTokenBalances = async () => {
  if (isAuthenticated) {
    const options = {
      chain: "bsc",
      address: Moralis.User.current().get("EthAddress"),
    };
    const balances = await Web3Api.account.getTokenBalances(options);

   
      const mainToken = balances.filter((items) => {
        if (items.symbol === "H2ON") {
          return items;
        } else {
          return null;
        }
      });
      console.log('main token',mainToken.length)
      if(mainToken.length !==0){
        console.log("H2ON Balance: " + mainToken[0].balance);
        const userCoinData = (mainToken[0].balance / 1000000000000000000).toFixed(
          5,
        );
        setUserToken(userCoinData);
      }
      else{
        
        setUserToken(0)
      }
    
 
    
  } else {
    return null;
  }
};
async function handleOk() {
  await Moralis.enableWeb3();

  const web3Provider = await Moralis.enableWeb3(); // Get ethers.js web3Provider

  const signer = web3Provider.getSigner();

  if (stakePool === 4) {
    console.log("nothing");
    const stakeOptions = {
      contractAddress: "0x0660C63c1D1fc1Ee5194bBB59b4844da9C7a849F",
      functionName: "deposit",

      signer: signer,
      abi: [
        {
          inputs: [
            {
              internalType: "uint256",
              name: "_amount",
              type: "uint256",
            },
            {
              internalType: "uint8",
              name: "_stakeType",
              type: "uint8",
            },
          ],
          name: "deposit",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
      ],

      params: {
        _amount: Moralis.Units.Token(value, "18"),
        _stakeType: "0",
      },
      msgValue: Moralis.Units.ETH(0.0035),
    };
    const transaction = await Moralis.executeFunction(stakeOptions);

    handleClose();

    console.log(transaction);
  } else {
    const stakeOptions = {
      contractAddress: "0x7348E415595e9396acE8Ce485c4DB85D71990076",
      functionName: "deposit",

      signer: signer,
      abi: [
        {
          inputs: [
            {
              internalType: "uint256",
              name: "_amount",
              type: "uint256",
            },
            {
              internalType: "uint8",
              name: "_stakeType",
              type: "uint8",
            },
          ],
          name: "deposit",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
      ],

      params: {
        _amount: Moralis.Units.Token(value, "18"),
        _stakeType: stakePool,
      },
      msgValue: Moralis.Units.ETH(0.0035),
    };
    const transaction = await Moralis.executeFunction(stakeOptions);

    handleClose();

    console.log(transaction);
  }
}
const logOut = async () => {
  await logout();
  console.log("logged out");
}
  return (
    <>
    <Modal
        open={open}
        onClose={handleClose}
        style={{
          position: "absolute",

          width: "100%",
          bgcolor: "#000000a6",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box
          style={{
            background: "rgb(14,48,116)",
            width: "25%",
            height: "50%",
            position: "absolute",

            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            border: "1px solid #07aef157",
            boxShadow: "rgb(74 74 104 / 10%) 0px 2px 2px -1px inset",
            padding: "8px 16px",
            borderRadius: "16px",
            marginBottom: "3%",
          }}
        >
          <div>
            <div>
              <div className="div-image-popup-1">
                <div className="popup-content">
                  <div className="popup-header">
                    <span
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        color: "white",
                        fontSize: "19px",
                        fontWeight: "600",
                        paddingTop: "0%",
                      }}
                    >
                      Stake in Pool
                    </span>
                    <a
                      onClick={handleClose}
                      style={{
                        color: "#fff !important",
                        background: "#10225e",
                        padding: "1% 1%",
                        borderRadius: "50px",
                        boxShadow: "0px 0px 5px #00bcd4ab",
                        height: "30px",
                        width: "30px",
                        fontSize: "15px",
                        textDecoration: "none",
                        textAlign: "center",
                        float: "right",
                      }}
                    >
                      X
                    </a>
                  </div>
                  <div className="popup-message">
                    <div className="pop-up-box">
                      <div className="value">{value} H2ON</div>

                      <div className="smaller-value">
                        {coinPrice * value} USD
                      </div>
                    </div>
                  </div>
                  <h2
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      color: "white",
                      fontSize: "14px",
                      paddingBottom: "5%",
                    }}
                  >
                    Balance:{" "}
                    <span style={{ display: "block", float: "right" }}>
                      {userToken} H2ON
                    </span>
                  </h2>

                  <Slider
                    value={typeof value === "number" ? value : 0}
                    onChange={handleSliderChange}
                    // defaultValue={50}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    color="primary"
                    max={userToken}
                  />

                  <div
                    style={{
                      textAlign: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <a
                      onClick={() => handleSliderChangeCustom("1")}
                      // className="box-pop-percentage"
                      style={{
                        backgorund: "#03a9f41c",
                        backgroundSize: "200%",
                        lineHeight: "19px",
                        padding: "1% 0%",
                        fontSize: "13px",
                        fontWeight: "400",
                        fontFamily: "Poppins",
                        color: "#fff !important",
                        textDecoration: "none",
                        height: "29px",
                        display: "inline-block",
                        width: "20%",
                        textAlign: "center",
                        alignSelf: "center",
                        borderRadius: "50px",
                        margin: "6% 1%",
                        border: "#07aef157 !important",
                        borderWidth: "1px solid",
                        boxShadow: " 0px 0px 5px #00bcd4ad",
                      }}
                    >
                      25 %
                    </a>
                    <a
                      onClick={() => handleSliderChangeCustom("2")}
                      style={{
                        backgorund: "#03a9f41c",
                        backgroundSize: "200%",
                        lineHeight: "19px",
                        padding: "1% 0%",
                        fontSize: "13px",
                        fontWeight: "400",
                        fontFamily: "Poppins",
                        color: "#fff !important",
                        textDecoration: "none",
                        height: "29px",
                        display: "inline-block",
                        width: "20%",
                        textAlign: "center",
                        alignSelf: "center",
                        borderRadius: "50px",
                        margin: "6% 1%",
                        border: "#07aef157 !important",
                        borderWidth: "1px solid",
                        boxShadow: " 0px 0px 5px #00bcd4ad",
                      }}
                    >
                      50 %
                    </a>
                    <a
                      onClick={() => handleSliderChangeCustom("3")}
                      style={{
                        backgorund: "#03a9f41c",
                        backgroundSize: "200%",
                        lineHeight: "19px",
                        padding: "1% 0%",
                        fontSize: "13px",
                        fontWeight: "400",
                        fontFamily: "Poppins",
                        color: "#fff !important",
                        textDecoration: "none",
                        height: "29px",
                        display: "inline-block",
                        width: "20%",
                        textAlign: "center",
                        alignSelf: "center",
                        borderRadius: "50px",
                        margin: "6% 1%",
                        border: "#07aef157 !important",
                        borderWidth: "1px solid",
                        boxShadow: " 0px 0px 5px #00bcd4ad",
                      }}
                    >
                      75 %
                    </a>
                    <a
                      onClick={() => handleSliderChangeCustom("4")}
                      style={{
                        backgorund: "#03a9f41c",
                        backgroundSize: "200%",
                        lineHeight: "19px",
                        padding: "1% 0%",
                        fontSize: "13px",
                        fontWeight: "400",
                        fontFamily: "Poppins",
                        color: "#fff !important",
                        textDecoration: "none",
                        height: "29px",
                        display: "inline-block",
                        width: "20%",
                        textAlign: "center",
                        alignSelf: "center",
                        borderRadius: "50px",
                        margin: "6% 1%",
                        border: "#07aef157 !important",
                        borderWidth: "1px solid",
                        boxShadow: " 0px 0px 5px #00bcd4ad",
                      }}
                    >
                      MAX
                    </a>
                  </div>
                  {/* <h2 className="pop-mini-h2o-title">
                    Annual ROI at current rates:{" "}
                    <span className="calculator">
                      $ 0.00 <i className=" data-arrow fa fa-calculator"></i>
                    </span>
                  </h2> */}
                  <a
                    onClick={handleOk}
                    style={{
                      color: "#fff",
                      marginBottom: "1%",
                      display: "inline-block",
                      /* background: rgb(138,97,248); */
                      /* background: -moz-linear-gradient (to right, rgb(0, 198, 255), rgb(0, 114, 255)); */
                      /* background: -webkit-linear-gradient(to right, rgb(0, 198, 255), rgb(0, 114, 255)); */
                      background:
                        "linear-gradient(to right, rgb(0, 198, 255), rgb(0, 114, 255))",
                      // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8a61f8', endColorstr='#ec5fe7',GradientType=1 );
                      border: "1px solid transparent",
                      borderRadius: "20px",
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                      lineHeight: "41px",
                      width: "100%",
                      letterSpacing: "0.07em",
                      textDecoration: "none !important",
                      textTransform: "uppercase",
                      padding: "0 30px",
                      transition: "0.3s",
                      webkitTransition: "0.3s",
                      boxShadow: "none",
                      animation: "glow1 3s infinite",
                      animationTimingFunction: "ease-out",
                      position: "relative",
                    }}
                  >
                    CONFIRM
                  </a>
                  <a
                    style={{
                      background: "#102167",
                      backgroundSize: "200%",
                      lineHeight: "19px",
                      padding: "3% 8%",
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#fff !important",
                      textDecoration: "none",
                      marginTop: "5%",
                      height: "45px",
                      display: "block",
                      width: "100%",
                      textAlign: "center",
                      borderRadius: "50px",
                      marginBottom: "5%",
                      boxShadow: " 0px 0px 9px 2px #00b7ff",
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    {loadingpage ? 
    <div className='App'>
   <div>
    {isAuthenticated ?
    <button className='btn-glow-small' onClick={()=>logOut()}>{userWalletMake.substring(0, 6)}...{userWalletMake.substring(userWalletMake.length - 4)}</button>:
    <button className='btn-glow-small' onClick={()=>login()} >Connect Wallet</button>
    }

   </div>
    <h1 style={{ fontFamily: "Poppins, sans-serif", color: "white" }}>
      Staking Pools
    </h1>

  {/* Arctic Pool */}
    <div className="ac-body"  onClick={()=>setViewMore(!viewMore)} >
          <div className="accordian-item">
            <div className="accordian-item-header">
              <div className="setrow">
                <div className="col-md-4">
                  <div className="setrow">
                    <div className="col-md-4">
                      <img src={arcticpool} className="icon-pool" />
                    </div>
                    <div className="afterimage">
                      <h2 className="accord-title">H2ON Arctic Pool</h2>
                      <h2 className="accord-sub-title">Stake H2ON</h2>
                      <h2 className="accord-mini-sub-title">Lock: 30 Days</h2>
                    </div>
                  </div>
                </div>
                {arcticPend === null || arcticPend==="" || arcticPend===0 || arcticPend==="0.00000" ? null : (
                  <div className="col-md-2">
                    <h2 className="accord-second-title">H2ON Earned</h2>
                    <h2 className="accord-second-sub-title">
                      {arcticPend} H2ON
                    </h2>
                  </div>
                )}
                <div className="col-md-2">
                  <h2 className="accord-three-title">APR</h2>
                  <h2 className="accord-three-sub-title">{arcticApr}%</h2>
                </div>
                <div className="col-md-2">
                  <h2 className="accord-four-title">Total Staked</h2>
                  <h2 className="accord-four-sub-title">
                    {arcticTotalStaked}{" "}
                    H2ON
                  </h2>
                </div>
                <div className="col-md-2">
                  <h2 className="accord-four-title">Starts In</h2>
                  <h2 className="accord-four-sub-title">19343440 Blocks</h2>
                </div>
                <div  >
                  <PlusOutlined
                    style={{
                      color: "white",
                     
                      fontSize: "20px",
                      justifySelf:'center'
                    }}
                    onClick={()=>setViewMore(!viewMore)}
                  />
               
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-plus-lg" viewBox="0 0 16 16" onClick={click} >
                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                  </svg> */}
                </div>
              </div>
            </div>
            <div
              className="accordian-item-body"
              style={{ maxHeight: viewMore ? 300 : 0 }}
            >
              <div className="accordian-item-body-content">
                <div className="setrow">
                  <div className="col-md-3">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="icon-fa">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-clock"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                          </svg>
                        </div>
                      </div>
                      <div className="col-md-9">
                        <h2 className="accord-five-title">ENDS IN</h2>
                      </div>
                    </div>
                    <h2 className="accord-five-sub-title">
                      {remainCon} Blocks
                    </h2>
                    <h2
                      className="accord-content-sub-title"
                      onClick={() =>
                        window.open(
                          "https://bscscan.com/address/0xe0e81c29a68bfdd7c48072fd94e7c58f1f0146c1",
                          "_blank",
                        )
                      }
                    >
                      See Token Info{" "}
                      <i className=" data-arrow fa fa-long-arrow-right"></i>
                    </h2>
                    <h2
                      className="accord-content-mini-sub-title"
                      onClick={() =>
                        window.open(
                          "https://www.h2o-securities.com/",
                          "_blank",
                        )
                      }
                      // href={"https://www.h2o-securities.com/"}
                      // target="_blank"
                    >
                      View Project Site{" "}
                      <i className=" data-arrow fa fa-long-arrow-right"></i>
                    </h2>

                    <br />
                    <br />
                    <div className="space">
                      <img
                        src={certik}
                        alt=""
                        style={{ height: "30px", resizeMode: "contain" }}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    {userStackedArc === null ||userStackedArc === "" ? null : (
                      <div>
                        <h2 className="accord-content-second-title">
                          Staked
                        </h2>
                        <h2 className="accord-second-sub-title">
                          {userStackedArc} H2ON
                        </h2>
                        <span style={{ color: "white", fontSize: "10px" }}>
                          {(userStackedArc * tokenPrice).toFixed(3)} USD
                        </span>
                        <h2 className="accord-content-second-title">
                          Locked
                        </h2>
                        <h2 className="accord-second-sub-title">
                          {userLockedArc} H2ON
                        </h2>
                        {/* <span style={{ color: "white", fontSize: "10px" }}>
                            {userLockedArc * tokenPrice}USD
                          </span> */}
                        <h2 className="accord-content-second-title">
                          Earned
                        </h2>
                        <h2 className="accord-second-sub-title">
                          {arcticPend} H2ON
                        </h2>
                        <span style={{ color: "white", fontSize: "10px" }}>
                          {(arcticPend * tokenPrice).toFixed(3)} USD
                        </span>
                        <br />
                        <a
                          className="btn-blue"
                          onClick={() => handleCompound("0")}
                        >
                          Compound <i className=" data-arrow fa fa-lock"></i>
                        </a>
                        <br />
                      </div>
                    )}
                  </div>
                  <div className="col-md-2">
                    <span className="accord-content-second-title">
                      <span className="span-blue"> Lock</span> Duration :
                    </span>
                    <h2 className="accord-second-sub-title">30 Days</h2>

                    <span className="accord-content-second-title">
                      <span className="span-blue"> Deposit</span> Fee :
                    </span>
                    <h2 className="accord-second-sub-title"> 2.00 %</h2>

                    <span className="accord-content-second-title">
                      <span className="span-blue"> Withdraw</span> Fee :
                    </span>
                    <h2 className="accord-second-sub-title">0.00 %</h2>
                  </div>
                  <div className="col-md-4">
                    <div className="box-glow">
                      <h2 className="accord-glow-title">START STAKING</h2>
                      {userStackedArc === null || userStackedArc === 0 || userStackedArc ==="" || userStackedArc ==="0.00000" || userStackedArc ==="0.0000"|| userStackedArc ==="0.000" ? (
                        <a
                          onClick={() => handleOpen("0")}
                          // value="0"
                          // onPress={handleOpen(value)}
                          className="btn-glow btn-hover-shine"
                        >
                          Stake
                        </a>
                      ) : (
                        <div style={{flexDirection:'row'}}>
                          <div className='btn-glow-small btn-hover-shine'>
                                    <MinusOutlined
                              style={{
                                color: "white",
                                
                                fontSize: "20px",
                              }}
                         
                            />
                          </div>
                         
                          <div className='btn-glow-small btn-hover-shine'>
                            <PlusOutlined
                              style={{
                                color: "white",
                                
                                fontSize: "20px",
                              }}
                              onClick={() => handleOpen("0")}
                            />
                          </div>
                   
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
         {/* Southern Pool */}
    <div className="ac-body" onClick={()=>setViewMore1(!viewMore1)}>
          <div className="accordian-item">
            <div className="accordian-item-header">
              <div className="setrow">
                <div className="col-md-4">
                  <div className="setrow">
                    <div className="col-md-4">
                      <img src={southernpool} className="icon-pool" />
                    </div>
                    <div className="afterimage">
                      <h2 className="accord-title">H2ON Southern Pool</h2>
                      <h2 className="accord-sub-title">Stake H2ON</h2>
                      <h2 className="accord-mini-sub-title">Lock: 90 Days</h2>
                    </div>
                  </div>
                </div>
                {southernPend === null || southernPend==="" || southernPend===0 || southernPend==="0.0"|| southernPend==="0.00"|| southernPend==="0.000"|| southernPend==="0.0000"|| southernPend==="0.00000" ? null : (
                  <div className="col-md-2">
                    <h2 className="accord-second-title">H2ON Earned</h2>
                    <h2 className="accord-second-sub-title">
                      {southernPend} H2ON
                    </h2>
                  </div>
                )}
                <div className="col-md-2">
                  <h2 className="accord-three-title">APR</h2>
                  <h2 className="accord-three-sub-title">{southernApr}%</h2>
                </div>
                <div className="col-md-2">
                  <h2 className="accord-four-title">Total Staked</h2>
                  <h2 className="accord-four-sub-title">
                    {southernTotalStaked}{" "}
                    H2ON
                  </h2>
                </div>
                <div className="col-md-2">
                  <h2 className="accord-four-title">Starts In</h2>
                  <h2 className="accord-four-sub-title">19343440 Blocks</h2>
                </div>
                <div className="morebutton">
                <PlusOutlined
                    style={{
                      color: "white",
                      
                      fontSize: "20px",
                    }}
                    onClick={()=>setViewMore1(!viewMore1)}
                  />
                 
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-plus-lg" viewBox="0 0 16 16" onClick={() => setViewMore1(!viewMore1)}>
                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                  </svg> */}
                </div>
              </div>
            </div>
            <div
              className="accordian-item-body"
              style={{ maxHeight: viewMore1 ? 300 : 0 }}
            >
              <div className="accordian-item-body-content">
                <div className="setrow">
                  <div className="col-md-3">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="icon-fa">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-clock"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                          </svg>
                        </div>
                      </div>
                      <div className="col-md-9">
                        <h2 className="accord-five-title">ENDS IN</h2>
                      </div>
                    </div>
                    <h2 className="accord-five-sub-title">
                      {remainCon} Blocks
                    </h2>
                    <h2
                      className="accord-content-sub-title"
                      onClick={() =>
                        window.open(
                          "https://bscscan.com/address/0xe0e81c29a68bfdd7c48072fd94e7c58f1f0146c1",
                          "_blank",
                        )
                      }
                    >
                      See Token Info{" "}
                      <i className=" data-arrow fa fa-long-arrow-right"></i>
                    </h2>
                    <h2
                      className="accord-content-mini-sub-title"
                      onClick={() =>
                        window.open(
                          "https://www.h2o-securities.com/",
                          "_blank",
                        )
                      }
                      // href={"https://www.h2o-securities.com/"}
                      // target="_blank"
                    >
                      View Project Site{" "}
                      <i className=" data-arrow fa fa-long-arrow-right"></i>
                    </h2>

                    <br />
                    <br />
                    <div className="space">
                      <img
                        src={certik}
                        alt=""
                        style={{ height: "30px", resizeMode: "contain" }}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    {userStackedSou === null ||userStackedSou === "" ? null : (
                      <div>
                        <h2 className="accord-content-second-title">
                          Staked
                        </h2>
                        <h2 className="accord-second-sub-title">
                          {userStackedSou} H2ON
                        </h2>
                        <span style={{ color: "white", fontSize: "10px" }}>
                          {(userStackedSou * tokenPrice).toFixed(3)} USD
                        </span>
                        <h2 className="accord-content-second-title">
                          Locked
                        </h2>
                        <h2 className="accord-second-sub-title">
                          {userLockedSou} H2ON
                        </h2>
                        {/* <span style={{ color: "white", fontSize: "10px" }}>
                            {userLockedArc * tokenPrice}USD
                          </span> */}
                        <h2 className="accord-content-second-title">
                          Earned
                        </h2>
                        <h2 className="accord-second-sub-title">
                          {southernPend} H2ON
                        </h2>
                        <span style={{ color: "white", fontSize: "10px" }}>
                          {(southernPend * tokenPrice).toFixed(3)} USD
                        </span>
                        <br />
                        <a
                          className="btn-blue"
                          onClick={() => handleCompound("1")}
                        >
                          Compound <i className=" data-arrow fa fa-lock"></i>
                        </a>
                        <br />
                      </div>
                    )}
                  </div>
                  <div className="col-md-2">
                    <span className="accord-content-second-title">
                      <span className="span-blue"> Lock</span> Duration :
                    </span>
                    <h2 className="accord-second-sub-title">90 Days</h2>

                    <span className="accord-content-second-title">
                      <span className="span-blue"> Deposit</span> Fee :
                    </span>
                    <h2 className="accord-second-sub-title"> 0.00 %</h2>

                    <span className="accord-content-second-title">
                      <span className="span-blue"> Withdraw</span> Fee :
                    </span>
                    <h2 className="accord-second-sub-title">0.15 %</h2>
                  </div>
                  <div className="col-md-4">
                    <div className="box-glow">
                      <h2 className="accord-glow-title">START STAKING</h2>
                      {userStackedSou === null || userStackedSou === 0 || userStackedSou ==""|| userStackedSou =="0.0"|| userStackedSou =="0.00"|| userStackedSou =="0.000"|| userStackedSou =="0.0000"|| userStackedSou =="0.00000" ? (
                        <a
                          onClick={() => handleOpen("1")}
                          // value="0"
                          // onPress={handleOpen(value)}
                          className="btn-glow btn-hover-shine"
                        >
                          Stake
                        </a>
                      ) : (
                        <div style={{flexDirection:'row'}}>
                        <div className='btn-glow-small btn-hover-shine'>
                                  <MinusOutlined
                            style={{
                              color: "white",
                              
                              fontSize: "20px",
                            }}
                       
                          />
                        </div>
                       
                        <div className='btn-glow-small btn-hover-shine'>
                          <PlusOutlined
                            style={{
                              color: "white",
                              
                              fontSize: "20px",
                            }}
                            onClick={() => handleOpen("1")}
                          />
                        </div>
                 
                      </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          {/* Indian Pool */}
    <div className="ac-body" onClick={()=>setViewMore2(!viewMore2)}>
          <div className="accordian-item">
            <div className="accordian-item-header">
              <div className="setrow">
                <div className="col-md-4">
                  <div className="setrow">
                    <div className="col-md-4">
                      <img src={indianpool} className="icon-pool" />
                    </div>
                    <div className="afterimage">
                      <h2 className="accord-title">H2ON Indian Pool</h2>
                      <h2 className="accord-sub-title">Stake H2ON</h2>
                      <h2 className="accord-mini-sub-title">Lock: 180 Days</h2>
                    </div>
                  </div>
                </div>
                {indianPend === null || indianPend=="" || indianPend==0|| indianPend=="0.0"|| indianPend=="0.00"|| indianPend=="0.000"|| indianPend=="0.0000"|| indianPend=="0.00000" ? null : (
                  <div className="col-md-2">
                    <h2 className="accord-second-title">H2ON Earned</h2>
                    <h2 className="accord-second-sub-title">
                      {indianPend} H2ON
                    </h2>
                  </div>
                )}
                <div className="col-md-2">
                  <h2 className="accord-three-title">APR</h2>
                  <h2 className="accord-three-sub-title">{indianApr}%</h2>
                </div>
                <div className="col-md-2">
                  <h2 className="accord-four-title">Total Staked</h2>
                  <h2 className="accord-four-sub-title">
                    {indianTotalStaked}{" "}
                    H2ON
                  </h2>
                </div>
                <div className="col-md-2">
                  <h2 className="accord-four-title">Starts In</h2>
                  <h2 className="accord-four-sub-title">19343440 Blocks</h2>
                </div>
                <div className="morebutton" >
                <PlusOutlined
                    style={{
                      color: "white",
                      
                      fontSize: "20px",
                    }}
                    onClick={()=>setViewMore2(!viewMore2)}
                  />
                 
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-plus-lg" viewBox="0 0 16 16" onClick={() => setViewMore2(!viewMore2)}>
                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                  </svg> */}
                </div>
              </div>
            </div>
            <div
              className="accordian-item-body"
              style={{ maxHeight: viewMore2 ? 300 : 0 }}
            >
              <div className="accordian-item-body-content">
                <div className="setrow">
                  <div className="col-md-3">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="icon-fa">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-clock"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                          </svg>
                        </div>
                      </div>
                      <div className="col-md-9">
                        <h2 className="accord-five-title">ENDS IN</h2>
                      </div>
                    </div>
                    <h2 className="accord-five-sub-title">
                      {remainCon} Blocks
                    </h2>
                    <h2
                      className="accord-content-sub-title"
                      onClick={() =>
                        window.open(
                          "https://bscscan.com/address/0xe0e81c29a68bfdd7c48072fd94e7c58f1f0146c1",
                          "_blank",
                        )
                      }
                    >
                      See Token Info{" "}
                      <i className=" data-arrow fa fa-long-arrow-right"></i>
                    </h2>
                    <h2
                      className="accord-content-mini-sub-title"
                      onClick={() =>
                        window.open(
                          "https://www.h2o-securities.com/",
                          "_blank",
                        )
                      }
                      // href={"https://www.h2o-securities.com/"}
                      // target="_blank"
                    >
                      View Project Site{" "}
                      <i className=" data-arrow fa fa-long-arrow-right"></i>
                    </h2>

                    <br />
                    <br />
                    <div className="space">
                      <img
                        src={certik}
                        alt=""
                        style={{ height: "30px", resizeMode: "contain" }}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    {userStackedInd === null ||userStackedInd === "" ? null : (
                      <div>
                        <h2 className="accord-content-second-title">
                          Staked
                        </h2>
                        <h2 className="accord-second-sub-title">
                          {userStackedInd} H2ON
                        </h2>
                        <span style={{ color: "white", fontSize: "10px" }}>
                          {(userStackedInd * tokenPrice).toFixed(3)} USD
                        </span>
                        <h2 className="accord-content-second-title">
                          Locked
                        </h2>
                        <h2 className="accord-second-sub-title">
                          {userLockedInd} H2ON
                        </h2>
                        {/* <span style={{ color: "white", fontSize: "10px" }}>
                            {userLockedArc * tokenPrice}USD
                          </span> */}
                        <h2 className="accord-content-second-title">
                          Earned
                        </h2>
                        <h2 className="accord-second-sub-title">
                          {indianPend} H2ON
                        </h2>
                        <span style={{ color: "white", fontSize: "10px" }}>
                          {(indianPend * tokenPrice).toFixed(3)} USD
                        </span>
                        <br />
                        <a
                          className="btn-blue"
                          onClick={() => handleCompound("2")}
                        >
                          Compound <i className=" data-arrow fa fa-lock"></i>
                        </a>
                        <br />
                      </div>
                    )}
                  </div>
                  <div className="col-md-2">
                    <span className="accord-content-second-title">
                      <span className="span-blue"> Lock</span> Duration :
                    </span>
                    <h2 className="accord-second-sub-title">180 Days</h2>

                    <span className="accord-content-second-title">
                      <span className="span-blue"> Deposit</span> Fee :
                    </span>
                    <h2 className="accord-second-sub-title"> 0.00 %</h2>

                    <span className="accord-content-second-title">
                      <span className="span-blue"> Withdraw</span> Fee :
                    </span>
                    <h2 className="accord-second-sub-title">0.15 %</h2>
                  </div>
                  <div className="col-md-4">
                    <div className="box-glow">
                      <h2 className="accord-glow-title">START STAKING</h2>
                      {userLockedInd === null || userLockedInd === 0 || userLockedInd ==""|| userLockedInd =="0.0"|| userLockedInd =="0.00"|| userLockedInd =="0.000"|| userLockedInd =="0.0000"|| userLockedInd =="0.00000" ? (
                        <a
                          onClick={() => handleOpen("2")}
                          // value="0"
                          // onPress={handleOpen(value)}
                          className="btn-glow btn-hover-shine"
                        >
                          Stake
                        </a>
                      ) : (
                        <div style={{flexDirection:'row'}}>
                        <div className='btn-glow-small btn-hover-shine'>
                                  <MinusOutlined
                            style={{
                              color: "white",
                              
                              fontSize: "20px",
                            }}
                       
                          />
                        </div>
                       
                        <div className='btn-glow-small btn-hover-shine'>
                          <PlusOutlined
                            style={{
                              color: "white",
                              
                              fontSize: "20px",
                            }}
                            onClick={() => handleOpen("2")}
                          />
                        </div>
                 
                      </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
              {/* Atlantic Pool */}
    <div className="ac-body" onClick={()=>setViewMore3(!viewMore3)}>
          <div className="accordian-item">
            <div className="accordian-item-header">
              <div className="setrow">
                <div className="col-md-4">
                  <div className="setrow">
                    <div className="col-md-4">
                      <img src={atlanticpool} className="icon-pool" />
                    </div>
                    <div className="afterimage">
                      <h2 className="accord-title">H2ON Atlantic Pool</h2>
                      <h2 className="accord-sub-title">Stake H2ON</h2>
                      <h2 className="accord-mini-sub-title">Lock: 365 Days</h2>
                    </div>
                  </div>
                </div>
                {atlanticPend === null || atlanticPend==""|| atlanticPend==0|| atlanticPend=="0.0"|| atlanticPend=="0.00"|| atlanticPend=="0.000"|| atlanticPend=="0.0000"|| atlanticPend=="0.00000" ? null : (
                  <div className="col-md-2">
                    <h2 className="accord-second-title">H2ON Earned</h2>
                    <h2 className="accord-second-sub-title">
                      {atlanticPend} H2ON
                    </h2>
                  </div>
                )}
                <div className="col-md-2">
                  <h2 className="accord-three-title">APR</h2>
                  <h2 className="accord-three-sub-title">{atlanticApr}%</h2>
                </div>
                <div className="col-md-2">
                  <h2 className="accord-four-title">Total Staked</h2>
                  <h2 className="accord-four-sub-title">
                    {atlanticTotalStaked}{" "}
                    H2ON
                  </h2>
                </div>
                <div className="col-md-2">
                  <h2 className="accord-four-title">Starts In</h2>
                  <h2 className="accord-four-sub-title">19343440 Blocks</h2>
                </div>
                <div className="morebutton" >
                <PlusOutlined
                    style={{
                      color: "white",
                      
                      fontSize: "20px",
                    }}
                    onClick={()=>setViewMore3(!viewMore3)}
                  />
                 
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-plus-lg" viewBox="0 0 16 16" onClick={() => setViewMore3(!viewMore3)}>
                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                  </svg> */}
                </div>
              </div>
            </div>
            <div
              className="accordian-item-body"
              style={{ maxHeight: viewMore3 ? 300 : 0 }}
            >
              <div className="accordian-item-body-content">
                <div className="setrow">
                  <div className="col-md-3">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="icon-fa">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-clock"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                          </svg>
                        </div>
                      </div>
                      <div className="col-md-9">
                        <h2 className="accord-five-title">ENDS IN</h2>
                      </div>
                    </div>
                    <h2 className="accord-five-sub-title">
                      {remainCon} Blocks
                    </h2>
                    <h2
                      className="accord-content-sub-title"
                      onClick={() =>
                        window.open(
                          "https://bscscan.com/address/0xe0e81c29a68bfdd7c48072fd94e7c58f1f0146c1",
                          "_blank",
                        )
                      }
                    >
                      See Token Info{" "}
                      <i className=" data-arrow fa fa-long-arrow-right"></i>
                    </h2>
                    <h2
                      className="accord-content-mini-sub-title"
                      onClick={() =>
                        window.open(
                          "https://www.h2o-securities.com/",
                          "_blank",
                        )
                      }
                      // href={"https://www.h2o-securities.com/"}
                      // target="_blank"
                    >
                      View Project Site{" "}
                      <i className=" data-arrow fa fa-long-arrow-right"></i>
                    </h2>

                    <br />
                    <br />
                    <div className="space">
                      <img
                        src={certik}
                        alt=""
                        style={{ height: "30px", resizeMode: "contain" }}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    {userStackedAtl === null ||userStackedAtl === "" ? null : (
                      <div>
                        <h2 className="accord-content-second-title">
                          Staked
                        </h2>
                        <h2 className="accord-second-sub-title">
                          {userStackedAtl} H2ON
                        </h2>
                        <span style={{ color: "white", fontSize: "10px" }}>
                          {(userStackedAtl * tokenPrice).toFixed(3)} USD
                        </span>
                        <h2 className="accord-content-second-title">
                          Locked
                        </h2>
                        <h2 className="accord-second-sub-title">
                          {userLockedAlt} H2ON
                        </h2>
                        {/* <span style={{ color: "white", fontSize: "10px" }}>
                            {userLockedArc * tokenPrice}USD
                          </span> */}
                        <h2 className="accord-content-second-title">
                          Earned
                        </h2>
                        <h2 className="accord-second-sub-title">
                          {atlanticPend} H2ON
                        </h2>
                        <span style={{ color: "white", fontSize: "10px" }}>
                          {(atlanticPend * tokenPrice).toFixed(3)} USD
                        </span>
                        <br />
                        <a
                          className="btn-blue"
                          onClick={() => handleCompound("3")}
                        >
                          Compound <i className=" data-arrow fa fa-lock"></i>
                        </a>
                        <br />
                      </div>
                    )}
                  </div>
                  <div className="col-md-2">
                    <span className="accord-content-second-title">
                      <span className="span-blue"> Lock</span> Duration :
                    </span>
                    <h2 className="accord-second-sub-title">365 Days</h2>

                    <span className="accord-content-second-title">
                      <span className="span-blue"> Deposit</span> Fee :
                    </span>
                    <h2 className="accord-second-sub-title"> 0.00 %</h2>

                    <span className="accord-content-second-title">
                      <span className="span-blue"> Withdraw</span> Fee :
                    </span>
                    <h2 className="accord-second-sub-title">0.15 %</h2>
                  </div>
                  <div className="col-md-4">
                    <div className="box-glow">
                      <h2 className="accord-glow-title">START STAKING</h2>
                      {userStackedAtl === null || userStackedAtl === 0 || userStackedAtl ==""|| userStackedAtl =="0.0"|| userStackedAtl =="0.00"|| userStackedAtl =="0.000"|| userStackedAtl =="0.0000"|| userStackedAtl =="0.00000" ? (
                        <a
                          onClick={() => handleOpen("3")}
                          // value="0"
                          // onPress={handleOpen(value)}
                          className="btn-glow btn-hover-shine"
                        >
                          Stake
                        </a>
                      ) : (
                        <div style={{flexDirection:'row'}}>
                        <div className='btn-glow-small btn-hover-shine'>
                                  <MinusOutlined
                            style={{
                              color: "white",
                              
                              fontSize: "20px",
                            }}
                       
                          />
                        </div>
                       
                        <div className='btn-glow-small btn-hover-shine'>
                          <PlusOutlined
                            style={{
                              color: "white",
                              
                              fontSize: "20px",
                            }}
                            onClick={() => handleOpen("3")}
                          />
                        </div>
                 
                      </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
                        {/* Pacific Pool */}
    <div className="ac-body" onClick={()=>setViewMore4(!viewMore4)}>
          <div className="accordian-item">
            <div className="accordian-item-header">
              <div className="setrow">
                <div className="col-md-4">
                  <div className="setrow">
                    <div className="col-md-4">
                      <img src={pacificpool} className="icon-pool" />
                    </div>
                    <div className="afterimage">
                      <h2 className="accord-title">H2ON Pacific Pool</h2>
                      <h2 className="accord-sub-title">Stake H2ON</h2>
                      <h2 className="accord-mini-sub-title">Lock: 730 Days</h2>
                    </div>
                  </div>
                </div>
                {pacificPend === null || pacificPend===""|| pacificPend===0 || pacificPend==="0.0000"|| pacificPend==="0.000"|| pacificPend==="0.00000" ? null : (
                  <div className="col-md-2">
                    <h2 className="accord-second-title">H2ON Earned</h2>
                    <h2 className="accord-second-sub-title">
                      {pacificPend} H2ON
                    </h2>
                  </div>
                )}
                <div className="col-md-2">
                  <h2 className="accord-three-title">APR</h2>
                  <h2 className="accord-three-sub-title">{pacificApr}%</h2>
                </div>
                <div className="col-md-2">
                  <h2 className="accord-four-title">Total Staked</h2>
                  <h2 className="accord-four-sub-title">
                    {pacificTotalStaked}{" "}
                    H2ON
                  </h2>
                </div>
                <div className="col-md-2">
                  <h2 className="accord-four-title">Starts In</h2>
                  <h2 className="accord-four-sub-title">1933146 Blocks</h2>
                </div>
                <div className="morebutton" >
                <PlusOutlined
                    style={{
                      color: "white",
                      
                      fontSize: "20px",
                    }}
                    onClick={()=>setViewMore4(!viewMore4)}
                  />
                 
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-plus-lg" viewBox="0 0 16 16" onClick={() => setViewMore4(!viewMore4)}>
                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                  </svg> */}
                </div>
              </div>
            </div>
            <div
              className="accordian-item-body"
              style={{ maxHeight: viewMore4 ? 300 : 0 }}
            >
              <div className="accordian-item-body-content">
                <div className="setrow">
                  <div className="col-md-3">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="icon-fa">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-clock"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                          </svg>
                        </div>
                      </div>
                      <div className="col-md-9">
                        <h2 className="accord-five-title">ENDS IN</h2>
                      </div>
                    </div>
                    <h2 className="accord-five-sub-title">
                      {remainCon2} Blocks
                    </h2>
                    <h2
                      className="accord-content-sub-title"
                      onClick={() =>
                        window.open(
                          "https://bscscan.com/address/0xe0e81c29a68bfdd7c48072fd94e7c58f1f0146c1",
                          "_blank",
                        )
                      }
                    >
                      See Token Info{" "}
                      <i className=" data-arrow fa fa-long-arrow-right"></i>
                    </h2>
                    <h2
                      className="accord-content-mini-sub-title"
                      onClick={() =>
                        window.open(
                          "https://www.h2o-securities.com/",
                          "_blank",
                        )
                      }
                      // href={"https://www.h2o-securities.com/"}
                      // target="_blank"
                    >
                      View Project Site{" "}
                      <i className=" data-arrow fa fa-long-arrow-right"></i>
                    </h2>

                    <br />
                    <br />
                    <div className="space">
                      <img
                        src={certik}
                        alt=""
                        style={{ height: "30px", resizeMode: "contain" }}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    {userLockedPac === null ||userLockedPac === "" ? null : (
                      <div>
                        <h2 className="accord-content-second-title">
                          Staked
                        </h2>
                        <h2 className="accord-second-sub-title">
                          {userLockedPac} H2ON
                        </h2>
                        <span style={{ color: "white", fontSize: "10px" }}>
                          {(userLockedPac * tokenPrice).toFixed(3)} USD
                        </span>
                        <h2 className="accord-content-second-title">
                          Locked
                        </h2>
                        <h2 className="accord-second-sub-title">
                          {userLockedPac} H2ON
                        </h2>
                        {/* <span style={{ color: "white", fontSize: "10px" }}>
                            {userLockedArc * tokenPrice}USD
                          </span> */}
                        <h2 className="accord-content-second-title">
                          Earned
                        </h2>
                        <h2 className="accord-second-sub-title">
                          {pacificPend} H2ON
                        </h2>
                        <span style={{ color: "white", fontSize: "10px" }}>
                          {(pacificPend * tokenPrice).toFixed(3)} USD
                        </span>
                        <br />
                        <a
                          className="btn-blue"
                          onClick={() => handleCompound("4")}
                        >
                          Compound <i className=" data-arrow fa fa-lock"></i>
                        </a>
                        <br />
                      </div>
                    )}
                  </div>
                  <div className="col-md-2">
                    <span className="accord-content-second-title">
                      <span className="span-blue"> Lock</span> Duration :
                    </span>
                    <h2 className="accord-second-sub-title">730 Days</h2>

                    <span className="accord-content-second-title">
                      <span className="span-blue"> Deposit</span> Fee :
                    </span>
                    <h2 className="accord-second-sub-title"> 0.00 %</h2>

                    <span className="accord-content-second-title">
                      <span className="span-blue"> Withdraw</span> Fee :
                    </span>
                    <h2 className="accord-second-sub-title">0.15 %</h2>
                  </div>
                  <div className="col-md-4">
                    <div className="box-glow">
                      <h2 className="accord-glow-title">START STAKING</h2>
                      {userStackedPac === null || userStackedPac === 0 || userStackedPac ===""|| userStackedPac ==="0.0"|| userStackedPac ==="0.00"|| userStackedPac ==="0.000"|| userStackedPac ==="0.0000" ? (
                        <a
                          onClick={() => handleOpen("4")}
                          // value="0"
                          // onPress={handleOpen(value)}
                          className="btn-glow btn-hover-shine"
                        >
                          Stake
                        </a>
                      ) : (
                        <div style={{flexDirection:'row'}}>
                        <div className='btn-glow-small btn-hover-shine'>
                                  <MinusOutlined
                            style={{
                              color: "white",
                              
                              fontSize: "20px",
                            }}
                       
                          />
                        </div>
                       
                        <div className='btn-glow-small btn-hover-shine'>
                          <PlusOutlined
                            style={{
                              color: "white",
                              
                              fontSize: "20px",
                            }}
                            onClick={() => handleOpen("4")}
                          />
                        </div>
                 
                      </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  </div>: null
    }
    </>
  );
}

export default App;
